var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isStagelineRoute && !_vm.currentCompanyId
    ? _c("div", { staticClass: "select-company-container" }, [
        _c(
          "div",
          { staticClass: "select-company-placement" },
          [
            _c("h2", { staticClass: "select-company-header" }, [
              _vm._v("\n      Select a Company\n    "),
            ]),
            [
              _c(
                "div",
                { staticClass: "select-company-options-container" },
                [
                  _c("company-selector", {
                    attrs: { companies: _vm.companies },
                    on: { "company-selected": _vm.companySelected },
                  }),
                ],
                1
              ),
            ],
            _c("hr", { staticClass: "divider" }),
            _c(
              "div",
              { staticClass: "continue d-flex justify-content-center" },
              [
                _c(
                  "b-link",
                  {
                    staticClass: "homepage-button",
                    attrs: { to: "/dashpanel" },
                  },
                  [
                    _vm._v("\n        Go to my account homepage\n        "),
                    _c("fa-icon", {
                      staticClass: "fa-sm pl-1 arrow",
                      attrs: { icon: "chevron-right" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ])
    : !_vm.loaded
    ? _c("ct-centered-spinner", { attrs: { height: "50px" } })
    : _c("div", { staticClass: "details-container container" }, [
        !_vm.isStagelineRoute
          ? _c(
              "div",
              {
                staticClass: "back-button d-flex align-items-center",
                on: { click: _vm.selectAnotherCompany },
              },
              [
                _c("fa-icon", {
                  staticClass: "mr-2 mb-2",
                  attrs: { icon: ["fas", "chevron-left"] },
                }),
                _c("p", { staticClass: "mt-1" }, [_vm._v("My Businesses")]),
              ],
              1
            )
          : _vm._e(),
        _c(
          "h3",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentCompany,
                expression: "currentCompany",
              },
            ],
          },
          [_vm._v("\n    " + _vm._s(_vm.currentCompany?.name) + "\n  ")]
        ),
        _c(
          "div",
          { staticClass: "details-panel" },
          [
            _c(
              "b-tabs",
              { attrs: { pills: "" } },
              [
                _c(
                  "b-tab",
                  {
                    attrs: { active: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [_c("strong", [_vm._v("My Company Info")])]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-tabs",
                      { attrs: { pills: "", vertical: "" } },
                      [
                        _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n              Company Details\n            "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [_c("company-profile")],
                          1
                        ),
                        _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n              Company People\n            "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [_c("company-people")],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }