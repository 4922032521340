<template>
  <div
    v-if="!isStagelineRoute && !currentCompanyId"
    class="select-company-container"
  >
    <div class="select-company-placement">
      <h2 class="select-company-header">
        Select a Company
      </h2>
      <template class="d-flex flex-column justify-content-center">
        <div class="select-company-options-container">
          <company-selector
            :companies="companies"
            @company-selected="companySelected"
          />
        </div>
      </template>
      <hr class="divider">
      <div class="continue d-flex justify-content-center">
        <b-link class="homepage-button" :to="'/dashpanel'">
          Go to my account homepage
          <fa-icon icon="chevron-right" class="fa-sm pl-1 arrow" />
        </b-link>
      </div>
    </div>
  </div>

  <ct-centered-spinner v-else-if="!loaded" height="50px" />

  <div v-else class="details-container container">
    <div
      v-if="!isStagelineRoute"
      class="back-button d-flex align-items-center"
      @click="selectAnotherCompany"
    >
      <fa-icon :icon="['fas', 'chevron-left']" class="mr-2 mb-2" />
      <p class="mt-1">My Businesses</p>
    </div>

    <h3 v-show="currentCompany">
      {{ currentCompany?.name }}
    </h3>

    <div class="details-panel">
      <b-tabs pills>
        <b-tab active>
          <template #title>
            <strong>My Company Info</strong>
          </template>
          <b-tabs pills vertical>
            <b-tab>
              <template #title>
                Company Details
              </template>
              <company-profile />
            </b-tab>
            <b-tab>
              <template #title>
                Company People
              </template>
              <company-people />
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ctCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'

export default {
  name: 'CompanyDetails',
  components: {
    CompanyProfile:  () => import('@/components/CompanyDetails/CompanyProfile.vue'),
    CompanyPeople:   () => import('@/components/CompanyDetails/CompanyPeople.vue'),
    CompanySelector: () => import('@/components/StagelineV2/shared/CompanySelector.vue'),
    ctCenteredSpinner,
  },
  data() {
    return {
      currentSelection: 'details',
      showAll: false,
    }
  },
  computed: {
    ...mapGetters('companies', [
      'currentCompany',
      'currentCompanyId',
      'companies',
    ]),
    ...mapGetters('companyDetails', [
      'detail',
      'dataMapDetails',
      'loaded',
    ]),
    ...mapGetters('stageline', [
      'isStagelineRoute',
    ]),
  },
  watch: {
    async currentCompanyId() {
      await this.fetchCompanyDetails()
    },
  },
  async mounted() {
    if (!this.companies.length) {
      await this.$store.dispatch('companies/loadMore')
    }

    if (this.currentCompanyId) {
      await this.fetchCompanyDetails()
    }
  },
  methods: {
    ...mapActions('companies', ['setCurrentCompany', 'clearCurrentCompany']),
    async companySelected(company) {
      const id = company.id
      await this.setCurrentCompany({ id, force: true })
    },
    async fetchCompanyDetails() {
      await this.$store.dispatch('companyDetails/fetch', { companyId: this.currentCompanyId })
    },
    selectAnotherCompany() {
      this.clearCurrentCompany()
    },
  },
}
</script>

<style lang="scss" scoped>
$black-100: #363636;

.select-company-container {
  width: 100%;
  padding: 1.0em 2.0em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;

  .select-company-placement {
    max-width: 700px;
    width: 100%;

    .select-company-header {
      display: inline-block;
      text-align: left;
      margin-top: 0;
      margin-bottom: 2.5em;
      font-weight: 700;
    }

    .select-company-options-container {
      display: flex;
      flex-flow: column nowrap;
      margin: 0 8em;
    }

    .divider {
      margin-top: 3.5em;
      margin-bottom: 3.5em;
    }

    .continue {
      margin-top: 8rem;
      margin-bottom: 1rem;

      .homepage-button {
        color: $ct-ui-primary;
      }
    }
  }
}

.details-container {

  .details-panel {
    width: 100%;

    ::v-deep .btn {
      border-radius: .25rem;
    }

    > .tabs {
      ::v-deep ul[role=tablist] {
        padding: 0.8rem 0;
        border-top: 1px solid $ct-ui-border-color-light;
      }

      ::v-deep .nav-link {
        &:not(.active) {
          color: $black-100;
        }

        &.active {
          background: white;
          color: $black-100;
        }
      }
      .tabs.row {
        margin-left: 0 !important;
        margin-right: 0 !important;

        ::v-deep .col-auto {

          .nav-pills {
            border-top: none;
          }

          background: #F4F4F5;
          border: 1px solid $ct-ui-border-color-light;
          border-radius: 0.5rem 0 0 0.5rem;
          width: 15.75rem;

          .nav-link {
            padding: 1rem;
            color: $black-100 !important;
            font-size: 1.25rem;
            font-weight: 400;
          }

          .nav-link.active {
            background-color: $gray-100 !important;
            font-weight: 600;
          }
        }

        ::v-deep .tab-content {
          padding: 0 2.875rem 2rem 2.875rem;
          border-radius: 0 0.5rem 0.5rem 0;
          border: 1px solid $ct-ui-border-color-light;
          border-left: none;
        }
      }
    }

    .tab-divider {
      width: 100%;
      position: relative;
      .vertical-rule {
        position: absolute;
        height: 2.875rem;
        border-left: solid lightgrey 1px !important;
        top: -10px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  ::v-deep .tab-content {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
    border-left: 1px solid $ct-ui-border-color-light !important;
  }
}
</style>
